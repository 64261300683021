<template>
  <div>
    <a-page-header title="新增企业" @back="() => $router.go(-1)" />
    <a-form
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      :from="addFromData"
    >
      <a-row>
        <a-col :span="16">
          <a-form-item label="企业名称">
            <a-input v-model="addFromData.company_name" />
          </a-form-item>
          <a-form-item label="简介">
            <a-textarea
              :auto-size="{ minRows: 4, maxRows: 6 }"
              v-model="addFromData.desc"
            />
          </a-form-item>

          <a-form-item label="多图">
            <a-upload
              name="file"
              @preview="handlePreview"
              :action="uploadImage"
              list-type="picture-card"
              :before-upload="beforeUpload"
              @change="handleChange2"
              :fileList="fileList"
            >
              <div v-if="fileList.length < 5">
                <a-icon type="plus" />
                <div class="ant-upload-text"></div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel2"
            >
              <img
                alt="example"
                style="max-width:450px;max-height:450px"
                :src="previewImage"
              />
            </a-modal>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="排序">
            <a-input-number :min='0' v-model="addFromData.list_order" style="width:140px" />
          </a-form-item>
          <a-form-item label="状态">
            <a-radio-group v-model="addFromData.status">
              <a-radio :value="1">
                启用
              </a-radio>
              <a-radio :value="0">
                禁用
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="推荐">
            <a-radio-group v-model="addFromData.recommended">
              <a-radio :value="1">
                是
              </a-radio>
              <a-radio :value="0">
                否
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="封面">
            <a-upload
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="uploadImage"
              :before-upload="beforeUpload"
              @change="handleChange"
            >
              <img
                v-if="imageUrl"
                :src="imageUrl"
                style="display:block;max-width:100px;max-height:100px"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="16">
          <a-form-item label="企业详情">
            <UE
              :sentContent="addFromData.content" @getUeContent="getUeContent"
            ></UE>
          </a-form-item>
        </a-col>

        <a-col :span="7">
          <a-button style="margin-left:80px" type="primary" @click="handOk"
            >保存</a-button
          >
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import UE from "@/components/UE/UE.vue";
import { uploadImage, companyDataSave, companyDetail } from "@/apis/index";
export default {
  data() {
    return {
      addFromData: {
        id: "",
        company_name: "",
        list_order: "",
        img_id: "",
        images: "",
        desc: "",
        content: "",
        recommended: 0,
        status: 1,
      },
      uploadImage,
      loading: false,
      imageUrl: "",
      fileList: [],
      imageList: [],
      previewVisible: false,
      previewImage: "",
    };
  },
  components: {
    UE,
  },
  computed: {},
  watch: {
    imageList(val) {
      let arr = [];
      if (this.imageList) {
        this.imageList.forEach((item) => {
          arr.push(item.uid);
        });
      }
      this.addFromData.images = arr.toString();
    },
  },
  created() {
    if (this.$route.query.id) {
      companyDetail({ id: this.$route.query.id }).then((res) => {
        if (res.code == 1) {
          if (res.data.image_list) {
            res.data.image_list.forEach((item) => {
              this.fileList.push({
                uid: item.id,
                name: "image.png",
                url: item.url,
                status: "done",
              });
              this.imageList.push({
                uid: item.id,
                name: "image.png",
                url: item.url,
                status: "done",
              });
            });
          }
          this.addFromData = { ...res.data };
          this.imageUrl = res.data.img_url;
        }
      });
    }
  },
  methods: {
    getUeContent(val){
      this.addFromData.content=val
    },
    handOk() {
      companyDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.$router.replace({ path: "/datamaintenances/company" });
        }
      });
    },
    handleCancel2() {
      this.previewVisible = false;
    },
    handlePreview(file) {
   
      this.previewImage = file.url || file.response.data.url;
      this.previewVisible = true;
    },
    handleChange2(info) {
     
      let { fileList } = info;
      if (info.file.status === "uploading") {
      }
      this.fileList = [...fileList];
      if (info.file.status === "done") {
        this.imageList.push({
          uid: info.file.response.data.id,
          name: "image.png",
          url: info.file.response.data.url,
          status: "done",
        });
      }
      if (info.file.status === "removed") {
        if (typeof info.file.uid == "string") {
          this.imageList.filter((item, index) => {
            if (item.uid == info.file.response.data.id) {
              
              this.imageList.splice(index, 1);
            }
          });
        } else {
          this.imageList.filter((item, index) => {
            if (item.uid == info.file.uid) {
          
              this.imageList.splice(index, 1);
            }
          });
        }
      }
    },
    handleChange(info) {
  
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.imageUrl = info.file.response.data.url;
        this.addFromData.img_id = info.file.response.data.id;
        this.loading = false;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped></style>
